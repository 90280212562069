<template>
  <div class="content">
    <table border="0" class="table">
      <!-- <tr class="thead">
        <th style="width: 240px"></th>
        <th style="width: 160px"></th>
        <th colspan="3" style="text-align: center"><70 mg/dL</th>
        <th colspan="2" style="text-align: center">70-180 mg/dL</th>
        <th colspan="3" style="text-align: center">>180 mg/dL</th>
        <th colspan="2" style="text-align: center">Overall</th>
      </tr> -->
      <tr class="tbody1">
        <td class="">Algorithm Run / Real Time</td>
        <td>BGM</td>
        <td>
          <div>MARD</div>
        </td>
        <td>
          <div>40-70</div>
          <div class="sub">mg/dL</div>
        </td>
        <td>
          <div>70-180</div>
          <div class="sub">mg/dL</div>
        </td>
        <td>
          <div>180-600</div>
          <div class="sub">mg/dL</div>
        </td>
        <td>
          <div>1 - 3</div>
          <div class="sub">Day</div>
        </td>
        <td>
          <div>4 - 10</div>
          <div class="sub">Day</div>
        </td>
        <td>
          <div>11 - 15</div>
          <div class="sub">Day</div>
        </td>
      </tr>
      <tr class="tbody">
        <td>Real Time - EGV<br />({{ serialNumber }})</td>
        <td>
          <!-- <span style="border:1px solid red;" onclick="changeTableData('SMBG')">SMBG</span>
             <span style="border:1px solid green;" onclick="changeTableData('YSI')">YSI</span> -->
          <a-radio-group default-value="SMBG" button-style="solid" class="radioBtn" size="small" @change="Handlechange">
            <a-radio-button value="SMBG"> SMBG </a-radio-button>
            <a-radio-button value="YSI"> YSI </a-radio-button>
          </a-radio-group>
        </td>

        <td>{{mardTableData.colMard}}</td>
        <td>{{mardTableData.col4070}}</td>
        <td>{{mardTableData.col70180}}</td>
        <td>{{mardTableData.col180600}}</td>
        <td>{{mardTableData.col13}}</td>
        <td>{{mardTableData.col410}}</td>
        <td>{{mardTableData.col1115}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      serialNumber: '',
      allTableData: {},
      //表格数据
      mardTableData: {},
    }
  },
  watch: {
    data(val) {
      console.log('表格数据', val)
      this.allTableData = val
      this.mardTableData = val.mardSmbgTableData
      this.serialNumber = val.serialNumber
    },
  },
  computed: {},
  methods: {
    //切换表格数据
    changeTableData(dataType) {
      if (dataType == 'SMBG') {
        this.mardTableData = this.allTableData.mardSmbgTableData
      } else if (dataType == 'YSI') {
        this.mardTableData = this.allTableData.mardYsiTableData
      }
    },
    Handlechange(e){
          this.changeTableData(e.target.value)
    }
  },
}
</script>
<style scoped lang="less">
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}

/* 去掉表头背景色 */
/deep/.Table {
  .ant-table-thead > tr > th {
    background-color: #fff !important;
    border-right: 1px solid #e2e2e1;
    .ant-table-header-column {
      font-size: 16px;
      font-weight: 700;
    }
    .ant-table-column-sorter {
      i {
        color: rgb(127, 98, 173) !important ;
      }
    }
  }
}

.table {
  width: 100%;
  border: none !important;
}
.table .thead {
  background-color: #fff !important;
}
.table .thead th {
  color: #353535;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 10px;
  border-right: 1px solid #e2e2e1;
  border-bottom: 1px solid #e2e2e1;
}

.table .thead th:first-child {
  border-right: none !important;
}

.tbody1,
.tbody {
  background-color: #fff !important;
}
.table .tbody1 td {
  padding: 6px 10px;
  border-right: 1px solid #e2e2e1;
  border-bottom: 1px solid #e2e2e1;
  text-align: center;
  font-weight: 700;
}
.table .tbody td {
  padding: 14px 10px;
  border-right: 1px solid #e2e2e1;
  border-bottom: 1px solid #e2e2e1;
  text-align: center;
  font-weight: 700;
}

.flex {
  display: flex;
  padding: 6px 10px;
}

.sub {
  font-weight: 400 !important;
  font-size: 12px !important;
}

/deep/.radioBtn {
  .ant-radio-button-wrapper-checked {
    background-color: rgb(127, 98, 173) !important;
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child  {
    border-color: rgb(127, 98, 173) !important;
  }
   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-child  {
    border-color: rgb(127, 98, 173) !important;
  }
}
</style>